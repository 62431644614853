import firebase from 'firebase/app'
import 'firebase/firestore'

var firebaseConfig = {
  apiKey: 'AIzaSyAHbax3LIW1fCBYV4bJ8EcnKVrY2JRq9nk',
  authDomain: 'onekaffee.firebaseapp.com',
  databaseURL: 'https://onekaffee.firebaseio.com',
  projectId: 'onekaffee',
  storageBucket: 'onekaffee.appspot.com',
  messagingSenderId: '89670534649',
  appId: '1:89670534649:web:42599c0d0556bb34d6a928'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export default firebase
