import { useState, useEffect } from 'react'
import firebase from '../../firebase'

export default (randomInt, type = 'random', search = '') => {
  const [items, setItems] = useState([]),
    saveData = (snapshot) => {
      const listItems = snapshot?.docs.map((doc) => ({
        id: doc.id,
        ...doc.data()
      }))
      setItems(listItems)
    }

  useEffect(() => {
    const ref = firebase.firestore().collection('words')
    if (type === 'search') {
      if (search && search !== '' && search.length >= 2) {
        const startCode = search.toLowerCase(),
          endCode = startCode.replace(/.$/, (c) =>
            String.fromCharCode(c.charCodeAt(0) + 1)
          )

        ref
          .where('wordSearch', '>=', startCode)
          .where('wordSearch', '<', endCode)
          .onSnapshot((snapshot) => saveData(snapshot))
      }
    } else {
      async function fetchData() {
        const hitsLo = await ref
          .where('random', '<=', randomInt)
          .orderBy('random', 'desc')
          .limit(1)
          .get()
        if (hitsLo.docs.length >= 1) {
          saveData(hitsLo)
        } else {
          const hitsHi = await ref
            .where('random', '>=', randomInt)
            .orderBy('random')
            .limit(1)
            .get()

          saveData(hitsHi)
        }
      }
      if (randomInt) {
        fetchData()
      }
    }
  }, [search, type, randomInt])

  return items
}
