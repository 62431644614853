import firebase from '../../firebase'
import useRandom from '../hooks/useRandom'

export default (type, newValue, oldValue) => {
  const ref = firebase.firestore().collection('words')
  if (type === 'add') {
    const random = useRandom(),
      newWord = newValue.word.replace(/[^a-zA-ZöÖäÄüÜß]/g, ''),
      newWordSearch = newWord.toLowerCase()

    return ref
      .add({
        random: random,
        word: newWord,
        wordSearch: newWordSearch
      })
      .then(() => true)
  } else if (type === 'edit') {
    const newWord = newValue.word.replace(/[^a-zA-ZöÖäÄüÜß]/g, ''),
      newWordSearch = newWord.toLowerCase()

    return ref
      .doc(oldValue.id)
      .update({
        word: newWord,
        wordSearch: newWordSearch
      })
      .then(() => true)
  } else if (type === 'delete') {
    return ref
      .doc(oldValue.id)
      .delete()
      .then(() => true)
  } else {
    return true
  }
}
