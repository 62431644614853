import React, { forwardRef } from 'react'
import useCrudWords from '../hooks/useCrudWords'
import styled from 'styled-components'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import Edit from '@material-ui/icons/Edit'

export default ({ words, reset }) => {
  const cols = [{ title: 'Wort', field: 'word' }],
    tableIcons = {
      Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
      Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
      Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
      Delete: forwardRef((props, ref) => (
        <DeleteOutline {...props} ref={ref} />
      )),
      DetailPanel: forwardRef((props, ref) => (
        <ChevronRight {...props} ref={ref} />
      )),
      Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />)
    },
    crudAction = (type, newValue, oldValue) => {
      return new Promise((resolve) => {
        useCrudWords(type, newValue, oldValue)
        reset()
        resolve()
      })
    }

  return (
    <Wrapper>
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={cols}
        localization={{
          body: {
            emptyDataSourceMessage: 'Bitte Wort suchen',
            addTooltip: 'Hinzufügen',
            deleteTooltip: 'Wort löschen',
            editTooltip: 'Wort bearbeiten',
            editRow: {
              deleteText: 'Soll das Wort entgültig gelöscht werden?',
              cancelTooltip: 'Abbrechen',
              saveTooltip: 'Speichern'
            }
          },
          header: {
            actions: 'Aktionen'
          }
        }}
        options={{
          search: false,
          sorting: false,
          grouping: false,
          filtering: false,
          draggable: false,
          paging: false
        }}
        data={words}
        editable={{
          onRowAdd: (newData) => crudAction('add', newData, false),
          onRowUpdate: (newData, oldData) =>
            crudAction('edit', newData, oldData),
          onRowDelete: (oldData) => crudAction('delete', false, oldData)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 0;

  table {
    margin-bottom: 0;
  }

  th {
    background-color: #ddd3eb;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  }

  tr:nth-of-type(2) {
    background-color: #f6f4fa;
  }

  th,
  td {
    padding: 0.5rem 1.5rem;
    font-size: 1.1rem;
  }

  td,
  input {
    font-size: 1.3rem;
  }

  .MuiToolbar-root {
    display: none;
  }
`
