import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SearchTable from '../components/SearchTable'
import useGetWords from '../hooks/useGetWords'
import Button from '@material-ui/core/Button'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import TextField from '@material-ui/core/TextField'

export default () => {
  const [searchStr, setSearchStr] = useState(''),
    [addOpen, setAddOpen] = useState(false),
    words = useGetWords(0, 'search', searchStr),
    reset = () => {
      setSearchStr('')
      if (addOpen) {
        clickCreate()
      }
      words.length = 0
    },
    clickCreate = () => {
      setAddOpen(!addOpen)
      document.querySelector('button[title="Hinzufügen"]').click()
    }

  return (
    <Layout>
      <SEO title="Konfiguration" />
      <Wrapper>
        <h1>Konfiguration</h1>
        <form
          noValidate
          autoComplete="off"
          onSubmit={(e) => e.preventDefault()}
        >
          <TextField
            className="suche"
            label="Suche"
            variant="outlined"
            value={searchStr}
            onChange={(e) => {
              setSearchStr(e.target.value)
            }}
          />
        </form>
        <SearchTable words={words} reset={reset} />
        <Button
          variant="contained"
          color="primary"
          className="btn"
          onClick={clickCreate}
          endIcon={<AddCircleIcon />}
        >
          Anlegen
        </Button>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  form {
    margin-bottom: 0.5rem;
    .suche {
      width: 100%;
    }
  }

  .btn {
    width: 100%;
    font-size: 1rem;
    margin-top: 2rem;
  }
`
